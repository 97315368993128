import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Button.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Partials/Common/Subaccount/Trigger/Mobile.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Common/EmptyList.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/Common/Table/Body.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/Partials/Common/Subaccount/Trigger/Header.vue";
import { default as __nuxt_component_5 } from "/opt/build/repo/components/Partials/Common/Subaccount/Trigger/Row.vue";
import { default as __nuxt_component_6 } from "/opt/build/repo/components/Common/Table/Wrapper.vue";
import { default as __nuxt_component_7 } from "/opt/build/repo/components/App/HocLoading.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue";
const _hoisted_1 = { class: "whitespace-nowrap" };
const _hoisted_2 = { class: "w-full h-full" };
const _hoisted_3 = {
  key: 0,
  class: "table"
};
import { Status, StatusType } from "@injectivelabs/utils";
import {
  executionOrderTypeToOrderTypes,
  executionOrderTypeToOrderExecutionTypes
} from "@/app/client/utils/activity";
export default /* @__PURE__ */ _defineComponent({
  __name: "Triggers",
  props: {
    denoms: {
      type: Array,
      default: () => []
    },
    side: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: ""
    },
    status: {
      type: Object,
      default: () => new Status()
    }
  },
  setup(__props) {
    const props = __props;
    const derivativeStore = useDerivativeStore();
    const { $onError } = useNuxtApp();
    const { success } = useNotifications();
    const { t } = useLang();
    const tabCountElement = document.getElementById(
      "activity-derivative-triggers-tab-count-default"
    );
    const actionStatus = reactive(new Status(StatusType.Idle));
    const markets = computed(
      () => derivativeStore.markets.filter(
        (m) => props.denoms.some(
          (denom) => [m.baseToken.denom, m.quoteDenom].includes(denom)
        )
      ).map(({ marketId }) => marketId)
    );
    const filteredTriggers = computed(() => {
      const orderTypes = executionOrderTypeToOrderTypes(props.type);
      const executionTypes = executionOrderTypeToOrderExecutionTypes(props.type);
      return derivativeStore.subaccountConditionalOrders.filter((order) => {
        const orderMatchesDenom = markets.value.length === 0 || markets.value.includes(order.marketId);
        const orderMatchesSide = !props.side || props.side === order.direction;
        const orderMatchesOrderTypes = !orderTypes || orderTypes.includes(order.orderType);
        const orderMatchesExecutionTypes = !executionTypes || executionTypes.includes(order.executionType);
        return orderMatchesDenom && orderMatchesSide && orderMatchesOrderTypes && orderMatchesExecutionTypes;
      });
    });
    onMounted(() => tabCountElement == null ? void 0 : tabCountElement.classList.add("hidden"));
    onUnmounted(() => tabCountElement == null ? void 0 : tabCountElement.classList.remove("hidden"));
    function handleCancelOrders() {
      actionStatus.setLoading();
      const action = filteredTriggers.value.length === 1 ? derivativeStore.cancelOrder(filteredTriggers.value[0]) : derivativeStore.batchCancelOrder(filteredTriggers.value);
      action.then(() => {
        success({
          title: t("trade.orders_cancelled")
        });
      }).catch($onError).finally(() => {
        actionStatus.setIdle();
      });
    }
    return (_ctx, _cache) => {
      const _component_AppButton = __nuxt_component_0;
      const _component_PartialsCommonSubaccountTriggerMobile = __nuxt_component_1;
      const _component_CommonEmptyList = __nuxt_component_2;
      const _component_CommonTableBody = __nuxt_component_3;
      const _component_PartialsCommonSubaccountTriggerHeader = __nuxt_component_4;
      const _component_PartialsCommonSubaccountTriggerRow = __nuxt_component_5;
      const _component_CommonTableWrapper = __nuxt_component_6;
      const _component_AppHocLoading = __nuxt_component_7;
      return _openBlock(), _createElementBlock("div", null, [
        (_openBlock(), _createBlock(_Teleport, { to: "#activity-derivative-triggers-tab-count" }, [
          _createElementVNode("span", null, "(" + _toDisplayString(_unref(filteredTriggers).length) + ")", 1)
        ])),
        (_openBlock(), _createBlock(_Teleport, { to: "#activity-toolbar-action" }, [
          _unref(filteredTriggers).length > 0 ? (_openBlock(), _createBlock(_component_AppButton, {
            key: 0,
            class: "text-red-500 bg-red-500 bg-opacity-10 font-semibold hover:text-white",
            status: _unref(actionStatus),
            "data-cy": "activity-cancel-all-button",
            onClick: handleCancelOrders
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t("trade.cancelAllOrders")), 1)
            ]),
            _: 1
          }, 8, ["status"])) : _createCommentVNode("", true)
        ])),
        _createVNode(_component_AppHocLoading, {
          class: "h-full",
          status: __props.status,
          "loader-class": __props.status.isLoading() ? "relative" : ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_CommonTableBody, {
                "show-empty": _unref(filteredTriggers).length === 0,
                class: "sm:hidden mt-3 max-h-lg overflow-y-auto"
              }, {
                empty: _withCtx(() => [
                  _createVNode(_component_CommonEmptyList, {
                    message: _ctx.$t("trade.emptyTriggers"),
                    class: "pb-4 grow bg-gray-900"
                  }, null, 8, ["message"])
                ]),
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(filteredTriggers), (trigger, index) => {
                    return _openBlock(), _createBlock(_component_PartialsCommonSubaccountTriggerMobile, {
                      key: `mobile-triggers-${index}-${trigger.orderHash}`,
                      class: "col-span-1",
                      trigger
                    }, null, 8, ["trigger"]);
                  }), 128))
                ]),
                _: 1
              }, 8, ["show-empty"]),
              _createVNode(_component_CommonTableWrapper, {
                "break-md": "",
                class: "hidden sm:block"
              }, {
                default: _withCtx(() => [
                  _unref(filteredTriggers).length > 0 ? (_openBlock(), _createElementBlock("table", _hoisted_3, [
                    _createVNode(_component_PartialsCommonSubaccountTriggerHeader),
                    _createElementVNode("tbody", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(filteredTriggers), (trigger, index) => {
                        return _openBlock(), _createBlock(_component_PartialsCommonSubaccountTriggerRow, {
                          key: `trigger-${index}-${trigger.orderHash}`,
                          trigger
                        }, null, 8, ["trigger"]);
                      }), 128))
                    ])
                  ])) : (_openBlock(), _createBlock(_component_CommonEmptyList, {
                    key: 1,
                    message: _ctx.$t("trade.emptyTriggers"),
                    "data-cy": "universal-table-nothing-found",
                    class: "pb-4 grow"
                  }, null, 8, ["message"]))
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }, 8, ["status", "loader-class"])
      ]);
    };
  }
});
